import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames";

import {IData} from "../../reducers/dataReducer"

import {O_Pick_random} from "../../tools/tools"

import {Topnav} from "../buttons/Topnav"
import TrainingList from "./TrainingList"
import {ContentPage} from "../pages/ContentPage"

import styles from './styles.module.css';

interface State {}
interface parentProps {
  content: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Trainings extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let item = O_Pick_random(this.props.data.trainings.byIds)

    return <div>

      <Helmet>
        <title>Trainingen - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`Trainingen - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <Topnav background={item.background} />
      <div className="container ocontent">
        <ContentPage title="Trainingen">
          {this.props.content}
        </ContentPage>
        <TrainingList />
      </div>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trainings)
