import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames"

import {IData} from "../../reducers/dataReducer"

import {O_Find_By} from "../../tools/tools"

import {Topnav} from "../buttons/Topnav"
import {ContentPage} from "../pages/ContentPage"
import TrainingList from "./TrainingList"

import styles from './styles.module.css';

interface State {}
interface parentProps {
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Training extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const specs = this.props.data.trainings.byIds
    let spec_id = O_Find_By(specs, "slug", this.props.match.params.title)

    if (spec_id < 0)
      return <div></div>

    const spec = specs[spec_id]
    const content = <div dangerouslySetInnerHTML={{ __html: spec.content }}></div>

    return <div>

      <Helmet>
        <title>{spec.title} - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`${spec.title} - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <Topnav background={spec.background}/>

      <div className="container ocontent">
        <ContentPage title={spec.title}>
          {content}
        </ContentPage>
        <h2 className="pagetitle">Trainingen</h2>
        <TrainingList />
      </div>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Training)
