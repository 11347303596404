import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from "classnames"
import {setColor} from "../../actions/ommenActions"

import {ILocationId, ISpecialtyId} from "../../reducers/dataReducer"

import {O_Find_By, O_Format_text} from "../../tools/tools"

import {Topnav} from "../buttons/Topnav"
import OLocationList from "../location/OLocationList"
import Facelist from "../humans/Facelist"

import styles from './location.module.scss';

interface State {
  loc_id: number;
  slug: string;
}

interface parentProps {
  match: any;
}

interface StoreProps {
  locations: {
    byIds: ILocationId
  },
  specialties: {
    byIds: ISpecialtyId
  }
}

interface DispatchProps {
  setColor: Function;
}

const mapStateToProps = state => {
  return {
    locations: state.data.locations,
    specialties: state.data.specialties,
  }
}

const mapDispatchToProps = dispatch => ({
  setColor: (color: string, id: number) => { dispatch(setColor(color, id))},
});

type Props = StoreProps & DispatchProps & parentProps

export class OLocation extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    let loc_id = O_Find_By(this.props.locations.byIds, "slug", this.props.match.params.name)

    if (loc_id > 0)
      this.props.setColor(this.props.locations.byIds[loc_id].color, loc_id)

    this.state = {
      loc_id: loc_id,
      slug: this.props.match.params.slug,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let slug = props.match.params.name
    if(slug != state.slug){

      let loc_id = O_Find_By(props.locations.byIds, "slug", props.match.params.name)

      // if (loc_id > 0)
      //   props.setColor(props.locations.byIds[loc_id].color, loc_id)

      return {
        loc_id: loc_id,
        slug: slug
      }
    }

    return null;
  }

  render() {
    if (this.state.loc_id < 1) {
      return <div>Locatie niet gevonden.</div>
    }

    const loc = this.props.locations.byIds[this.state.loc_id]

    return <div>
      <Helmet>
        <title>{loc.title} - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`${loc.title} - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <Topnav />
      <div className="container ocontent">
        <h1 className={styles.title}>{loc.title}</h1>
        <div className="row">
          <div className="col col-md-6 col-lg-8 brood">
            <div>
              <span className={classNames(styles.subtitle, "bold")} style={{color: loc.color}}>{loc.name}</span><br/>
              <p>
                {loc.street} {loc.number} <br/>
                {loc.zipcode} {loc.city} <br/>
                088-0005757
              </p>

              {this.note(loc)}

            </div>
            {this.openings(loc)}
            {this.walkin(loc)}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {this.specialtyList(loc)}
          </div>
        </div>

        <Facelist ids={loc.humans}/>
        <OLocationList/>

      </div>
    </div>
  }

  openings(loc) {
    return <div className={styles.openhours}>
      <span className={classNames(styles.subtitle, "bold")}>Openingstijden </span><br/>
      <table>
        <tbody>
          {this.otime(1, loc.o_mo_open, loc.o_mo_close)}
          {this.otime(2, loc.o_tu_open, loc.o_tu_close)}
          {this.otime(3, loc.o_we_open, loc.o_we_close)}
          {this.otime(4, loc.o_th_open, loc.o_th_close)}
          {this.otime(5, loc.o_fr_open, loc.o_fr_close)}
          {this.otime(6, loc.o_sa_open, loc.o_sa_close)}
          {this.otime(0, loc.o_su_open, loc.o_su_close)}
        </tbody>
      </table>
    </div>
  }

  otime(day, open, close) {
    const daylist = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag']

    const now = new Date()
    const daynr = now.getDay()

    let dayClass = (daynr == day)? styles.active : ''

    if (open === "00:00")
      return <tr className={classNames(dayClass)}>
        <td className={styles.day}>{daylist[day]}</td>
        <td className={styles.closed} colSpan={3}>Gesloten</td>
      </tr>

    return <tr className={dayClass}>
      <td className={styles.day}>{daylist[day]}</td>
      <td>{open}</td>
      <td> - </td>
      <td>{close}</td>
    </tr>
  }


  walkin(loc) {
    if (loc.walkin)
      return <div className={styles.walkin}>
        <span className={classNames(styles.subtitle, "bold")}>Inloopspreekuur</span><br/>
        <p dangerouslySetInnerHTML={{ __html: O_Format_text(loc.walkin) }}></p>
      </div>
    return <span></span>
  }

  note(loc) {
    if (loc.note)
      return <div className={styles.note}>
        *{loc.note}
      </div>
    return <span></span>
  }

  specialtyList(loc){
    if (loc.specialties.length == 0 )
      return <div></div>

    return <div className={styles.speclist}>
      {loc.specialties.map((id) => {
          let spec = this.props.specialties.byIds[id]
          return <Link to={`/specialisatie/${spec.slug}`} key={id}><div >{spec.title}</div></Link>
        }
      )}
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OLocation);
