import * as React from "react";
import * as ReactGA from 'react-ga';
import {connect} from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Route, Link, Switch } from "react-router-dom";
import {Helmet} from "react-helmet";

import {IData} from "../reducers/dataReducer"

import {initData, loadData}  from "../actions/ommenActions"

import ScrollToTop from './ScrollToTop'
import LoadBarrier from "./LoadBarrier"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import OLocation from "./location/OLocation"
import About from "./humans/About"
import Page from "./pages/Page"
import Blog from "./blogs/Blog"
import Blogs from "./blogs/Blogs"
import Issue from "./issues/Issue"
import Issues from "./issues/Issues"
import Specialty from "./specialties/Specialty"
import Training from "./trainings/Training"
import Home from "./home/Home"

const history = createBrowserHistory();
const trackingId = "UA-168577877-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

interface State {
}

interface parentProps {}

interface StoreProps {
}

interface DispatchProps {
  initData: Function;
  loadData: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  initData: (data: IData) => { dispatch( initData(data) )},
  loadData: () => {dispatch( loadData())},
});

type Props = StoreProps & DispatchProps & parentProps

export class App extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.loadData();
    let dataset = document.getElementById("dataset").innerHTML;
    let x = JSON.parse(dataset)
    this.props.initData(x)
  }


  render() {
    return   <Router history={history}>
      <ScrollToTop>
        <Helmet>
            <title>Fysiotherapie Den Ommelanden</title>
            <meta name="description" content="Fysiotherapie Den Ommelanden" />
        </Helmet>
        <Header />

        <div className="content-block container-fluid">

        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />

          <LoadBarrier>
            <Switch>
              <Route path="/locatie/:name" component={OLocation} />
              <Route path="/over/:name" component={About} />
              <Route path="/specialisatie/:title" component={Specialty} />
              <Route path="/training/:title" component={Training} />
              <Route path="/klacht/:gid/:title" component={Issue} />
              <Route path="/klachten/:part" component={Issues} />
              <Route path="/blog" exact component={Blogs} />
              <Route path="/blog/:id" component={Blog} />
              <Route path="/blog/:id/:title" component={Blog} />
              {/* Legacy routes*/}
              <Route path="/fysiotherapie/:slug/:subslug" component={Page} />

              <Route path="/:slug" component={Page} />

            </Switch>
            </LoadBarrier>
          </div>

        <Footer />

      </ScrollToTop>
    </Router>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
