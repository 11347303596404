import * as React from "react";
import {Link} from "react-router-dom";

import classNames from "classnames"
import {OButton} from "./OButtons"

import styles from './button.module.scss';

interface State {
}

interface Props {
  background?: string;
  title?: string;
  active?: string;
  noPadding?: boolean;
  show?: number;
  subtitle?: string;
}

export class Topnav extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {

  let headStyle = {}
  let topclass = styles.headWithoutImage

  if (this.props.background) {
    headStyle = {
      backgroundImage: `url(${this.props.background})`,
    }
    topclass = styles.headWithImage
  }

  let hasTitle = ''
  if (this.props.title)
    hasTitle = styles.hasTitle

  return <div style={headStyle} className={classNames(topclass, hasTitle)}>
      <div className={classNames("container", {[`${styles.noPadding}`]: this.props.noPadding})}>
        {(this.props.show)? this.special() : this.normal()}
        {this.title()}
        {(this.props.subtitle)? <div dangerouslySetInnerHTML={{ __html: this.props.subtitle }} className={styles.subtitle}></div> : ''}
      </div>
    </div>
  }

  normal() {
    return <div className={`${styles.topnav} row`}>
      <div className="col-12 col-md-4"><OButton target="/afspraak" title="afspraak maken" /></div>
      <div className="col-12 col-md-4 "><OButton target="/afzeggen" title="afspraak afzeggen" /></div>
      <div className="col-12 col-md-4"><OButton target="/contact" title="contact" /></div>
    </div>
  }

  special() {
    if (this.props.show == 1)
      return <div className={`${styles.topnav} row`}>
        <div className="col-12 col-md-4"><OButton extraclass={styles.appointment} target="/afspraak" title="afspraak maken" /></div>
        <div className="col-12 col-md-4 "><OButton target="/afzeggen" title="afspraak afzeggen" /></div>
      </div>
   return <div className={`${styles.topnav} row`}>
      <div className="col-12 col-md-4"><OButton target="/afspraak" title="afspraak maken" /></div>
      <div className="col-12 col-md-4">
        <OButton extraclass={styles.appointment} target="/afzeggen" title="afspraak afzeggen" />
      </div>
    </div>

  }

  title() {
    if (this.props.title)
      return <div><h1 className={styles.herotitle}>{this.props.title}</h1></div>
    else
      return ''
  }
}
