import * as React from "react";
import {connect} from 'react-redux';

import {IData} from "../../reducers/dataReducer"

import {O_ID_Sort} from "../../tools/tools"
import {OButton} from "../buttons/OButtons"

import styles from './location.module.scss';

interface State {
}

interface parentProps {
  human_id: number;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class OLocationList extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    let list = O_ID_Sort(this.props.data.locations.byIds, "title")
    return <div className="row">
      {list.map((location) => this.location(location))}
    </div>
  }

  location(loc) {
    let color = ""

    if (this.props.human_id) {
      if (loc.humans.includes(this.props.human_id))
        color = loc.color
    }
    else if (loc.id == this.props.data.active_loc) {
      color = loc.color
    }

    return <div key={loc.id} className={`${styles.location} col-12 col-md-6 col-lg-4 col-xl-3`}>
      <OButton target={`/locatie/${loc.slug}`} title={loc.title} bg={color} />
    </div>
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(OLocationList)
