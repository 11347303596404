import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from 'classnames'
import {IData} from "../../reducers/dataReducer"
import {setColor} from "../../actions/ommenActions"

import {O_Find_By, O_Format_text} from "../../tools/tools"

import {Topnav} from "../buttons/Topnav"
import OLocationList from "../location/OLocationList"

import styles from './humans.module.scss'

interface State {
  hid: number;
}

interface parentProps {
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {
  setColor: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  setColor: (color: string, id: number) => { dispatch(setColor(color, id))},
});

type Props = StoreProps & DispatchProps & parentProps

export class About extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    let hid = O_Find_By(this.props.data.humans.byIds, "slug", this.props.match.params.name)

    if (hid > 0) {
      let loc_id = this.setLocationColor(hid)
    }

    this.state = {
      hid: hid,
    }
  }

  render() {

    if (this.state.hid < 1)
      return <div></div>

    return this.human(this.props.data.humans.byIds[this.state.hid])
  }

  setLocationColor(hid: number) {
    const locs = this.props.data.locations.byIds
    Object.keys(locs).forEach((id)=> {
      if(locs[id].humans.includes(hid))
        this.props.setColor(locs[id].color, locs[id].id)
    })
  }

  big(big) {
    if (!big)
      return
    if (big.length === 0)
      return

    return <p className={styles.big}>BIG-nummer: {big}</p>
  }


  human(human) {
    const hstyle = {
      backgroundImage: `url(${human.photo_lg})`,
    }

    return <div style={hstyle} className={classNames("container", styles.holder)}>

      <Helmet>
        <title>{human.name} - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`${human.name} - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <Topnav noPadding={true} />
      <div className={classNames(styles.smallimage, "row")}>
        <div className="col"><img src={human.photo_sm} className="img-fluid"/></div>
      </div>

      <div className={classNames("ocontent")}>

        <div className={classNames(styles.textblock, "row", "d-flex", "align-items-end")}>
          <div className={classNames("col-12 col-md-8")}>
            <div className={styles.bio}>
              <span className={styles.name}>{human.name}</span>
              <p dangerouslySetInnerHTML={{ __html: O_Format_text(human.content) }}></p>
              { this.big(human.big) }
            </div>
          </div>
        </div>

        <OLocationList human_id={this.state.hid}/>
      </div>
    </div>
  }


}


export default connect(mapStateToProps, mapDispatchToProps)(About);
