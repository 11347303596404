import * as React from "react";
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import classNames from "classnames";
import styles from './footer.module.scss';

import {IData} from "../../reducers/dataReducer"

import twitter from  '../../images/twitter.svg'
import facebook from  '../../images/facebook.svg'
import phone from  '../../images/phone-solid.svg'



interface State {}

interface parentProps {
  location: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Footer extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    return <div className="container-fluid">

      <div className={styles.phonecontact}>
        <p>
          <a className={classNames(styles.social, styles.twitter)} target="_blank" href="https://twitter.com/DenOmmelanden"><img alt="Twitter" src={twitter}/></a>
          <a className={classNames(styles.social, styles.facebook)}  target="_blank" href="https://www.facebook.com/Fysiotherapie-Den-Ommelanden-405345179529736/"><img alt="Facebook" src={facebook}/></a>
          <a href="tel:0880005757"><img alt="Telefoon" src={phone}/> 088-0005757</a>
        </p>
      </div>

      <div className={styles.footerHolder}>
        <div className={styles.links} >
          <Link to="/contact">Contact</Link>
          <Link to="/tarieven">Tarieven</Link>
          <Link to="/locaties">Locaties</Link>
          <Link to="/privacy-klachtenregeling">Privacy</Link>
          <a href="/Betalingsvoorwaarden.pdf" target="_blank">Betalingsvoorwaarden</a>
        </div>
      </div>
    </div>
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));

