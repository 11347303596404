import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import classNames from "classnames"
import {statusTypes} from "../../actions/actionTypes"
import {IData, IExperience} from "../../reducers/dataReducer"

import {OButton} from "../buttons/OButtons"
import {O_Format_text, O_ID_Sort} from "../../tools/tools"

import {Topnav} from "../buttons/Topnav"

import styles from './front.module.scss'

interface State {}

interface parentProps {}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Home extends React.Component<Props, State> {
  refs: any;

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    const subtitle="Beste sporter, ten tijde van de corona pandemie maken we gebruik van een reserveringstool om de 1.5 m regel zoveel mogelijk te kunnen waarborgen. Wilt u bij ons komen sporten, reserveer dan tijdig uw plek!"

    return <div>
      <Topnav background={this.props.data.background} title="Fysiotherapie"/>

      <div className={classNames(styles.contentHolder)}>
        <div className={classNames("container", "ocontent")}>
          <h2 className={styles.helping}>Waarmee kunnen we je helpen?</h2>
          {this.issues()}
          {this.experienceBlock()}
        </div>
      </div>
    </div>
  }

  issues() {
    let list = O_ID_Sort(this.props.data.groups.byIds, "order_id")

    return <div className={classNames(styles.issuesHolder, "row")}>
      {list.map((group) =>
        <div key={group.id} className={classNames("col-12 col-md-4")}>{this.group(group)}</div>
      )}
    </div>
  }

  group(group) {
    return <Link to={`/klacht/${group.id}/${group.slug}`}><div className={styles.issue}>
      <h2>{group.title}</h2>
      <p className={styles.issuetext} dangerouslySetInnerHTML={{ __html: group.short }}></p>
    </div></Link>
  }

  experienceBlock(){
    return <div className="row">
      <div className="col-12 col-md-3">
        <OButton target="/anders" title="Andere vraag" extraclass={styles.other} />
        <OButton target="/blog" title="Blog" extraclass={styles.blog} />
      </div>
      <div ref="block" className={classNames(styles.block, "col-12 col-md-9")}>
        <div className={styles.blockExp}>
          {this.experiences()}
        </div>
        <div className={classNames(styles.qcol, "d-flex")}>
          {this.qualizorgwidget()}
        </div>
      </div>
    </div>
  }

  experiences() {
    let len = this.props.data.experiences.length
    if (len < 1)
      return <div></div>

    let half = Math.floor(len / 2)
    let xp1 = this.props.data.experiences.slice(0, half)
    let xp2 = this.props.data.experiences.slice(half, len)

    return <div style={{width: "100%"}}>
      <h3 className={classNames(styles.exp_header)}>Ervaringen</h3>
      <div className="row">
        <div className={"col-12 col-lg 6 pl-0 pr-0"}>
          {xp1.map((xp) => this.experience(xp, styles.xp1))}
        </div>
        <div className={"col-12 col-lg 6  pl-0 pr-0"}>
          {xp2.map((xp) => this.experience(xp, styles.xp2))}
        </div>
      </div>
    </div>
  }

  experience(xp: IExperience, style){
    return <div key={xp.id} className={classNames(style, "row")}>
      <div className={classNames(styles.xp, "col", "brood")}>
        <p dangerouslySetInnerHTML={{ __html: O_Format_text(xp.content) }}></p>
        <span className="bold">{xp.name}</span>, {xp.location}
      </div>
    </div>
  }

  qualizorgwidget() {
    return <div className={styles.widget}>
      <iframe
        src="https://qualizorgwidget.nl/stream/s/95JF4051575"
        seamless
        height="100%">
      </iframe>
    </div>

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home)
