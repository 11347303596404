import * as React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from 'classnames'
import {IData} from "../../reducers/dataReducer"
import {O_ID_Sort} from '../../tools/tools'

import Header from './Header'
import Privacy from './Privacy'
import {OLabel, ORow, OSubmit, OWarning} from './FormHelpers'
import {OButton} from "../buttons/OButtons"
import {Topnav} from "../buttons/Topnav"

import styles from './appointment.module.scss'

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { Form, FormGroup, Label, Input, Col } from 'reactstrap';

interface State {
  appointment: {
    name: string;
    // surname: string;
    // dateofbirth: string;
    // street_and_nr: string;
    // zipcode: string;
    // city: string;
    email: string;
    phone: string;
    subject: string;
    // doctor: string;
    // referal: boolean;
    therapist: number;
    location: number;
  }
  show_warning: boolean;
}

interface parentProps {
  history: any;
  background: string;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Appointment extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      appointment: {
        name: "",
        // surname: "",
        // dateofbirth: "",
        // street_and_nr: "",
        // zipcode: "",
        // city: "",
        email: "",
        phone: "",
        subject: "",
        // doctor: "",
        // referal: true,
        therapist: 0,
        location: 0,
      },
      show_warning: false,
    }
  }


  render() {
    return (
      <div className="ocontent">

        <Helmet>
          <title>Afspraak maken - Fysiotherapie Den Ommelanden</title>
          <meta name="description" content={`Afspraak maken - Fysiotherapie Den Ommelanden`} />
        </Helmet>

        <Topnav background={this.props.background} show={1}/>

        <div className={classNames(styles.formHolder, "row justify-content-md-center")}>
          <div className="col-12">
            {this.form()}
          </div>
        </div>
      </div>
      )
  }

  form() {
    const a = this.state.appointment
    return (
      <div className={classNames(styles.form, "container")}>

      <ORow title="Naam">
        <Input
              invalid={a.name.trim().length == 0}
              type="text"
              name="name"
              value={a.name}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Email">
        <Input
              invalid={a.email.trim().length == 0}
              type="text"
              name="email"
              value={a.email}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Telefoonnummer">
        <Input
              invalid={a.phone.trim().length == 0}
              type="text"
              name="phone"
              value={a.phone}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Klacht / Aandoening">
          <Input
              invalid={a.subject.trim().length == 0}
              type="textarea"
              name="subject"
              value={a.subject}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

{/*       <ORow title="Huisarts">
        <Input
              invalid={a.doctor.trim().length == 0}
              type="text"
              name="doctor"
              value={a.doctor}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>*/}

{/*      <FormGroup row>
       <OLabel>Heeft u een verwijzing</OLabel>
        <div className={classNames(styles.selectHolder, styles.selectHolderLeft, "col-6", "col-sm-4")} onClick={() => this.setreferal(true)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass(true))}>Ja</div>
        </div>
        <div className={classNames(styles.selectHolder, styles.selectHolderRight, "col-6", "col-sm-4")} onClick={() => this.setreferal(false)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass(false))}>Nee</div>
        </div>
      </FormGroup>*/}

      <ORow title="Welke therapeut">
        <Input
              type="select"
              name="therapist"
              value={a.therapist}
              onChange={(e) =>this.handleInputChange(e)}
              children={
                [<option key={-1} value={-1}>Geen voorkeur</option>,
                ...O_ID_Sort(this.props.data.humans.byIds, "surname").map((t) => {
                  if (t.supportstaff == false)
                    return <option key={t.id} value={t.id}>{t.name}</option>
                }
                  )]}
          />
      </ORow>

      <ORow title="Welke locatie">
        <Input
              type="select"
              name="location"
              value={a.location}
              onChange={(e) =>this.handleInputChange(e)}
              children={
                [<option key={-1} value={-1}>Geen voorkeur</option>,
                ...O_ID_Sort(this.props.data.locations.byIds, "name").map((loc) => {
                  return <option key={loc.id} value={loc.id}>{loc.name}</option>
                }
                  )]}
          />
      </ORow>

      {this.state.show_warning? <OWarning /> : ''}

      <OSubmit click={() => this.submit()} />

      <Privacy />

      </div>
    )
  }

  // setreferal(option) {
  //   this.setState({
  //     appointment: {
  //       ...this.state.appointment,
  //       referal: option
  //     }
  //   })
  // }

  // refclass(option) {
  //   if (option == this.state.appointment.referal)
  //     return styles.selected
  //   return styles.notSelected
  // }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newstate = {
      ...this.state.appointment,
      [name]: value,
    }

    this.setState({
      appointment: {
        ...newstate,
      },
      show_warning: false
    });
  }

  validate() {
    const a = this.state.appointment
    return a.name.trim().length > 0 &&
    a.email.trim().length > 0 &&
    a.phone.trim().length > 0 &&
    a.subject.trim().length > 0
    // a.doctor.trim().length > 0
  }

  submit() {
    if (!this.validate()){
      this.setState({
        show_warning: true
      })
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    let that = this

    axios.post("/contact/appointment",{
      appointment: this.state.appointment
    }, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }).then(function(result) {
      console.log("worked")
      that.props.history.push('/afspraak_verzonden')
    }).catch(function (error) {
      if (error.response) {
        console.log('Error', error.message);
      }
    })
  }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointment));
