import * as React from "react";
import {Link} from "react-router-dom";

import classNames from "classnames"

import styles from './button.module.scss';

interface State {
}

interface Props {
  target: string;
  title: string;
  bg?: string;
  extraclass?: string;
}

export class OButton extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    let bstyle = { }

    if (this.props.bg)
      bstyle = {
        ...bstyle,
        backgroundColor: this.props.bg,
      }

    return <Link
      to={this.props.target}
      style={bstyle}
      className={classNames(styles.button, styles.content_button, this.props.extraclass)}>
      <div className="d-flex justify-content-center align-items-center">{this.props.title}</div>
    </Link>
  }
}
