import { Reducer } from "redux"
import { actionTypes, statusTypes } from "../actions/actionTypes"

export interface ILocation {
  id: number;
  name: string;
  title: string;
  color: string;
  street: string;
  number: string;
  zipcode: string;
  city: string;
  humans: number[];
  specialties: number[];
  slug: string;
  note: string;
  walkin: string;

  o_mo_open: string;
  o_mo_close: string;
  o_tu_open: string;
  o_tu_close: string;
  o_we_open: string;
  o_we_close: string;
  o_th_open: string;
  o_th_close: string;
  o_fr_open: string;
  o_fr_close: string;
  o_sa_open: string;
  o_sa_close: string;
  o_su_open: string;
  o_su_close: string;
}

export interface ILocationId {
  [id: number]: ILocation;
}

export interface IHuman{
  id: number;
  name: string;
  surname: string;
  supportstaff: boolean;
  photo_sm: string;
  photo_lg: string;
  slug: string;
  content: string;
  big: string;
}

export interface IHumanId {
  [id: number]: IHuman;
}

export interface ISpecialty {
  id: number;
  title: string;
  content: string;
  slug: string;
  background: string;
}

export interface ISpecialtyId {
  [id: number]: ISpecialty
}

export interface ITraining {
  id: number;
  title: string;
  content: string;
  slug: string;
  background: string;
}

export interface ITrainingId {
  [id: number]: ITraining
}
export interface Menu {
  id: number;
  title: string;
  link: string;
}

export interface IPage {
  slug: string;
  title: string;
  content: string;
  template: string;
  background: string;
}

export interface IPageId {
  [id: string]: IPage;
}

export interface IBlog {
  id: number;
  title: string;
  intro: string;
  content: string;
  background_sm: string;
  background: string;
  user: string;
  date: string;
  meta: string;
}

export interface IBlogId {
  [id: string]: IBlog;
}

export interface IExperience {
  id: number;
  name: string;
  location: string;
  content: string;
}

export interface IGroupItems {
  id: number;
  title: string;
  slug: string;
}

export interface IGroup {
  id: number;
  title: string;
  short: string;
  slug: string;
  content: string;
  order_id: number;
  background: string;
  items: IGroupItems[];
}

export interface IGroupId {
  [id: string]: IGroup;
}

export interface IIssue {
  id: number;
  title: string;
  short: string;
  content: string;
  part: string;
  order_id: number;
  background: string;
}

export interface IIssueId {
  [id: string]: IIssue;
}

export interface IData {
  status: statusTypes,
  locations: {
    byIds: ILocationId
  },
  humans: {
    byIds: IHumanId,
    ordered: number[],
  },
  specialties: {
    byIds: ISpecialtyId,
  },
  trainings: {
    byIds: ITrainingId,
  },
  menu: Menu[],
  experiences: IExperience[],
  groups: {
    byIds: IGroupId,
  },
  issuelist: string[], // can be deprecated
  background: string,
  pages: {
    bySlugs: IPageId,
  },
  blogs: {
    byIds: IBlogId,
  },
  issues: {
    byParts: IIssueId,
  }
  color: string,
  active_loc: number,
}

const initState: IData = {
  status: statusTypes.INIT,
  locations: {
    byIds: {}
  },
  humans: {
    byIds: {},
    ordered: [],
  },
  specialties: {
    byIds: {},
  },
  trainings: {
    byIds: {},
  },
  menu: [],
  experiences: [],
  groups: {
    byIds: {}
  },
  issuelist: [],
  background: '',
  pages: {
    bySlugs: {}
  },
  blogs: {
    byIds: {}
  },
  issues: {
    byParts: {}
  },
  color: '#009fe3',
  active_loc: 0
}

export const data: Reducer<IData> = (state = initState, action) => {
    switch(action.type) {

      case actionTypes.OMMEN_INIT: {
        let data = action.payload.data
        // data.status = statusTypes.LOADED

        return {
          ...state,
          menu: data.menu,
          experiences: data.experiences,
          groups: data.groups,
          issuelist: data.issuelist,
          background: data.background,
        }
        break;
      }

      case actionTypes.OMMEN_LOADED: {
        let data = action.payload

        return {
          ...state,
          status: statusTypes.LOADED,
          locations: data.locations,
          humans: data.humans,
          specialties: data.specialties,
          trainings: data.trainings,
          blogs: data.blogs,
        }
      }

      case actionTypes.OMMEN_PAGE_LOADED: {
        let data = action.payload

        let pages = state.pages
        pages.bySlugs[data.slug] = data.page

        return {
          ...state,
          pages: pages,
        }
      }

      case actionTypes.OMMEN_BLOG_LOADED: {
        let data = action.payload

        let blogs = state.blogs
        blogs.byIds[data.id] = data.blog

        return {
          ...state,
          blogs: blogs,
        }
      }

      case actionTypes.OMMEN_GROUP_LOADED: {
        let data = action.payload

        let group = state.groups.byIds[data.id]
        group.content = data.content
        group.items = data.items
        group.background = data.background

        let groups = state.groups
        groups.byIds[data.id] = group

        return {
          ...state,
          groups: groups,
        }
      }

      case actionTypes.OMMEN_ISSUE_LOADED: {
        let data = action.payload

        let issues = state.issues
        issues.byParts[data.part] = data.issue

        return {
          ...state,
          issues: issues,
        }
      }

      case actionTypes.OMMEN_SET_COLOR: {

        return {
          ...state,
          color: action.payload.color,
          active_loc: action.payload.active_loc,
        }
      }

    }

    return state
}
