import * as React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Link} from "react-router-dom";

import classNames from 'classnames'
import {IData} from "../../reducers/dataReducer"
import {O_ID_Sort} from '../../tools/tools'

import Privacy from './Privacy'
import {OLabel, ORow, OSubmit, OWarning} from './FormHelpers'

import styles from './appointment.module.scss'

import phone from  '../../images/phone-solid.svg'

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { Form, FormGroup, Label, Input, FormText, Col, Row } from 'reactstrap';

interface State {
  appointment: {
    name: string;
    phone: string;
    email: string;
    subject: string;
    message: string;
  },
  show_warning: boolean;
}

interface parentProps {
  history: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Contact extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      appointment: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      show_warning: false,
    }
  }


  render() {
    return (
      <div>
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">
            <div className="row">
              <div className="col-12 col-md-4"></div>
              <div className="col-12 col-md-8">
                <h1 className={styles.pagetitle}>Contactformulier</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">
            <div className={classNames(styles.hint, "row")}>
              <div className="col-0 col-md-4"></div>
              <div className="col-12 col-md-8">
                Dit formulier is niet geschikt om een afspraak te maken. Wilt u zich aanmelden klik dan op <Link to="/afspraak">afspraak maken</Link>
              </div>
            </div>

            {this.form()}
            <br />
            {this.phone()}
          </div>
        </div>
      </div>
      )
  }

  phone() {
    return <div className={styles.phonecontact}>
      <h2>Bellen</h2>
      <p>U kunt ook telefonisch contact opnemen met het secretariaat</p>
      <p><a href="tel:0880005757"><img alt="Telefoon" src={phone}/> 088-0005757</a></p>
    </div>
  }

  secretary() {
    return (
      <div className={styles.contactHolder}>
    <Row>
      <Label sm={4} className={classNames(styles.label)}>Secretariaat</Label>

      <div className={classNames("mediumblue", "col-sm-2", styles.contacts)}>Telefoon</div>
      <div className={classNames("col-sm-6", styles.info)}>06 - 294 690 36<br/>
        <span>(Bereikbaar tussen 9.00-12.30 en 13.00-17.00 uur)</span>
      </div>
    </Row>
    <Row>
      <div className="col-sm-4"></div>
      <div className={classNames("mediumblue", "col-sm-2", styles.contacts)}>Adres</div>
      <div className={classNames("col-sm-6", styles.info)}>Ambachtsschoolstraat 4<br/>
        9981 JX Uithuizen
      </div>
    </Row>
    </div>
    )
  }

  form() {
    const a = this.state.appointment
    return (
      <div className={styles.form}>
       <ORow title="Naam">
          <Input
              invalid={a.name.trim().length == 0}
              type="text"
              name="name"
              value={a.name}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Email">
          <Input
              invalid={a.email.trim().length == 0}
              type="text"
              name="email"
              value={a.email}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Telefoonnummer">
          <Input
              invalid={a.phone.trim().length == 0}
              type="text"
              name="phone"
              value={a.phone}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Onderwerp">
          <Input
              invalid={a.subject.trim().length == 0}
              type="text"
              name="subject"
              value={a.subject}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

      <ORow title="Bericht">
          <Input
              invalid={a.message.trim().length == 0}
              type="textarea"
              name="message"
              value={a.message}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

        {this.state.show_warning? <OWarning /> : ''}

        <OSubmit click={() => this.submit()} />
        <Privacy />
      </div>
    )
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newstate = {
      ...this.state.appointment,
      [name]: value,
    }

    this.setState({
      appointment: {
        ...newstate,
      }
    });
  }

  validate() {
    const a = this.state.appointment
    return a.name.trim().length > 0 &&
    a.email.trim().length > 0 &&
    a.phone.trim().length > 0 &&
    a.subject.trim().length > 0 &&
    a.message.trim().length > 0
  }

  submit() {
    if (!this.validate()){
      this.setState({
        show_warning: true
      })
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    let that = this

    axios.post("/contact/contact",{
      contact: this.state.appointment
    }, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }).then(function(result) {
      console.log("worked")
      that.props.history.push('/contact_verzonden')
    }).catch(function (error) {
      if (error.response) {
        console.log('Error', error.message);
      }
    })
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
