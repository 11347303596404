import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from 'classnames'

import {O_ID_Sort, slugify} from "../../tools/tools"
import {IData, IPage, IBlog} from "../../reducers/dataReducer"
import {statusTypes} from "../../actions/actionTypes"

import styles from './blog.module.scss';

interface State {}

interface parentProps {}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
});

type Props = StoreProps & DispatchProps & parentProps

export class Blogs extends React.Component<Props, State> {

  constructor(props) {
    super(props);
  }

  render() {

    if (!this.haveData()) {
      return <div>Loading...</div>
    }

    let list = O_ID_Sort(this.props.data.blogs.byIds, "id").reverse()

    return <div className={classNames(styles.blog)}>

      <Helmet>
        <title>Blog - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`Blog - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <div className={classNames(styles.blogsholder, "container ocontent")}>
        <div className="row">
          {list.map((blog) => this.blog(blog))}
        </div>
      </div>
    </div>
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

  blog(blog: IBlog) {
    const intro = <div className={styles.introtext} dangerouslySetInnerHTML={{ __html: blog.intro }}></div>

    return <div key={blog.id} className={classNames(styles.blogitem, "col-12 col-sm-6 col-md-6 col-lg-4")}>
      <Link to={`/blog/${blog.id}/${slugify(blog.title)}`}>
        <img src={blog.background_sm} className={classNames("img-fluid", styles.img)}/>
        <div className={styles.intro}>
          <h3>{blog.title}</h3>
          {intro}
          <div className={classNames("row no-gutters", styles.introfooter)}>
            <div className={"offset-4 col-4"}>{blog.user}</div>
            <div className={classNames("col-4", styles.introdate)}>{blog.date}</div>
          </div>
        </div>
      </Link>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blogs)
